import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import { useState, useEffect, useRef } from "react";
import { ModuleStateType, MOBILE_WIDTH } from "utils/constants";
import "./PlayerDetails.scss";

const PlayerDetails = ({ previousSection, nextSection, submodule, courseLink, moduleLink, handleClickSectionItem, isSurveyComplete, setIsSurveyComplete }) => {
	const fetchData = { url: "/api/GetPreviousAndNextSubmodules/" + moduleLink.Id + "/" + submodule.Id };
	const [data, setData] = useState(null);
	const [isNarrow, setIsNarrow] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);
	const playerDetailsRef = useRef(null);
	const resizeObserverRef = useRef(null); 

	const WIDTH_FOR_NARROW_BUTTONS = 680;

	// Truncate text utility function
	const truncateText = (text, maxLength = 20) => {
		return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
	};

	// Adjust narrow-mode based on element and window size
	const handleResize = (entries) => {
		if (isMobile) {
			setIsNarrow(false); // Prevent narrow-mode for mobile view
		}
		else {
			for (let entry of entries) {
				const width = entry.contentRect.width;
				console.debug("PlayerDetails: W = ", width);
				setIsNarrow(width < WIDTH_FOR_NARROW_BUTTONS);
			}
		}
	};

	useEffect(() => {
		// Update isMobile state on window resize
		const handleWindowResize = () => {
			setIsMobile(window.innerWidth <= MOBILE_WIDTH);
		};
		window.addEventListener("resize", handleWindowResize);

		// Cleanup on unmount
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	useEffect(() => {
		if (data && playerDetailsRef.current) {
			if (!resizeObserverRef.current) {
				resizeObserverRef.current = new ResizeObserver(handleResize);
			}
			resizeObserverRef.current.observe(playerDetailsRef.current);
		}
		return () => {
			if (resizeObserverRef.current) {
				resizeObserverRef.current.disconnect();
				resizeObserverRef.current = null;
			}
		};
	}, [data, isMobile]);

	const renderPreviousButton = () => {

		if (previousSection) {
			const text = truncateText(previousSection.title);
			return (
				<Button
					type={ButtonType.Secondary}
					text={text}
					flipped="true"
					miniMobile="true"
					onClick={() => handleClickSectionItem(previousSection.id)}
				/>
			);
		} else if (data?.Previous) {
			data.Previous.Course = courseLink;
			data.Previous.Module = moduleLink;
			return (
				<Button
					type={ButtonType.Primary}
					text="Previous Submodule"
					flipped="true"
					to={`/player#courseId-${courseLink.Id}-moduleId-${moduleLink.Id}-submoduleId-${data.Previous.Id}`}
					onClick={resetIsSurveyComplete}
				/>
			);
		}
		return null;
	};

	const renderNextButton = () => {
		if (nextSection) {
			const text = truncateText(nextSection.title);
			return (
				<Button
					type={ButtonType.Secondary}
					text={text}
					miniMobile="true"
					onClick={() => handleClickSectionItem(nextSection.id)}
				/>
			);
		} else if (data?.Next) {
			data.Next.Course = courseLink;
			data.Next.Module = moduleLink;
			const lockedByJourney = data.Next.State === ModuleStateType.CourseTraversal;
			return (
				<Button
					type={ButtonType.Primary}
					text="Next Submodule"
					to={`/player#courseId-${courseLink.Id}-moduleId-${moduleLink.Id}-submoduleId-${data.Next.Id}`}
					disabled={lockedByJourney}
					tooltip={lockedByJourney ? "Complete this content to unlock" : null}
					onClick={resetIsSurveyComplete}
				/>
			);
		}
		return null;
	};

	const resetIsSurveyComplete = () => {
		setIsSurveyComplete(false);
	}

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div
				className={`player-details ${isNarrow ? "narrow-mode" : ""}`}
				ref={playerDetailsRef}
			>
				{data && (
					<div className="player-details-navigation">
						<div className="player-details-navigation-prev">{renderPreviousButton()}</div>
						<div className="player-details-navigation-return">
							<Button
								type={ButtonType.Primary}
								text="Return to Module"
								to={`/course-view#courseId-${courseLink.Id}-moduleId-${moduleLink.Id}`}
								flipped
							/>
						</div>
						<div className="player-details-navigation-next">{renderNextButton()}</div>
					</div>
				)}
			</div>
		</DataFetcher>
	);
};

export default PlayerDetails;

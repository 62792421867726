import "./Nps.scss";
import { useState, useRef, useEffect, useCallback } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFetchOptions } from "../../utils/fetch";
import { DataFetcher } from "../../components/DataFetcher";

const Nps = ({ isVisible }) => {
	const [isHidden, setIsHidden] = useState(!isVisible);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const countdownTimerLength = 5;
	const countdownRef = useRef(countdownTimerLength);
	const timerRef = useRef(null);
	const [countdown, setCountdown] = useState(countdownRef.current);
	const [fetchData, setFetchData] = useState({});

	const handleScaleClick = (i) => {
		const options = getFetchOptions({
			method: "POST"
		});
		setFetchData({ url: `api/v3/NpsScore/${i}/`, options: options });
	};

	const handleData = useCallback((data) => {
		if (data) {
			setHasSubmitted(true);
			sessionStorage.setItem("nps", "false");
			startCountdown();
		}
	}, []);

	const startCountdown = () => {
		timerRef.current = setInterval(() => {
			countdownRef.current -= 1;
			setCountdown(countdownRef.current);
			if (countdownRef.current <= 0) {
				clearInterval(timerRef.current);
				setIsHidden(true);
			}
		}, 1000);
	};

	const handleClose = () => {
		const options = getFetchOptions({
			method: "POST"
		});
		setFetchData({ url: `api/NpsEligibility/`, options: options });
		setIsHidden(true);
		clearInterval(timerRef.current);
		sessionStorage.setItem("nps", "false");
	};

	useEffect(() => {
		setIsHidden(!isVisible);
	}, [isVisible]);

	useEffect(() => {
		return () => clearInterval(timerRef.current);
	}, []);

	return (
		<div className={`nps ${isHidden ? "hide" : ""}`}>
			<DataFetcher fetchData={fetchData} setData={handleData} />
			<div className="nps-header">
				<h2>We value your feedback</h2>
				<div className="nps-header-close" onClick={handleClose}>
					<FontAwesomeIcon icon={faXmark} />
				</div>
			</div>
			<div className="nps-body">
				{!hasSubmitted ? (
					<>
						<p className="nps-body-toptext">Please take a moment to rate your experience with us on a scale from 0 to 10.</p>
						<div className="nps-body-scale">
							{Array.from({ length: 11 }, (_, index) => (
								<div
									key={index}
									className="nps-body-scale-number"
									onClick={() => handleScaleClick(index)}
								>
									{index}
								</div>
							))}
						</div>
					</>
				) : (
					<>
							<p className="nps-body-toptext">Thank you for your feedback. It helps us provide you with the best experience possible.</p>
							<p className="nps-body-countdown">This will automatically close after {countdown} seconds.</p>
					</>
				)}
			</div>
		</div>
	);
};

export default Nps;

import { DataFetcher } from "components/DataFetcher";
import { useModal } from "components/Modal/context/ModalContext";
import { PackageType, packageIsScorm } from "features/Shared/ContentData/PackageType";
import useLogout from "hooks/useLogout";
import { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import "./Player.scss";
import PlayerBreadcrumb from "./components/PlayerBreadcrumb";
import PlayerDetails from "./components/PlayerDetails";
import PlayerMedia from "./components/PlayerMedia";
import PlayerSidebar from "./components/PlayerSidebar";
import usePlayerMessageReceiver from "./hooks/usePlayerMessageReceiver";
import usePlayerResize from "./hooks/usePlayerResize";
import usePlayerTerminate from "./hooks/usePlayerTerminate";
import SurveyContainer from "./Assessments/SurveyContainer";
import { getFetchOptions } from "utils/fetch";

const NoContentType = Object.freeze({
	LoggedInNotExist: 1,
	LoggedInNotEnrolled: 2,
	NotLoggedIn: 3
});

const Player = () => {
	const mediaRef = useRef();
	const location = useLocation();
	const navigate = useNavigate();
	const { logout } = useLogout(navigate);
	const { openModal } = useModal();
	const [fetchData, setFetchData] = useState({});
	const [data, setData] = useState(null);
	const expectedMessageOrigin = useRef("");
	const [assessment, setAssessment] = useState(false);
	const [resetAssessment, setResetAssessment] = useState(false);
	const fullToC = useRef();
	const [sidebarPresent, setSidebarPresent] = useState(assessment ? false : false);
	const [sidebarSections, setSidebarSections] = useState([]);
	const [selectedSection, setSelectedSection] = useState(0);
	const [detailNavigationSections, setDetailNavigationSections] = useState({});
	const [isSurveyComplete, setIsSurveyComplete] = useState(false);
	const {
		setReceivedPlayerWidth,
		setReceivedPlayerHeight,
		setBreadcrumbHeight,
		calculatedPlayerWidth,
		calculatedPlayerHeight,
		resetPlayerDimensions
	} = usePlayerResize(window, sidebarPresent);
	const breadcrumbHeight = useRef();
	const idList = useRef([]);

	const unloading = useRef(0);
	let blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return data && currentLocation.pathname !== nextLocation.pathname && ((data.Path && data.Path.length) || (assessment));
	});
	const { postTerminate } = usePlayerTerminate(blocker, unloading, data);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	const handleOpenNoContentModal = useCallback((noContentType) => {
		let message = <div>You will be redirected to the login page. You will be returned to this page after logging in.</div>;
		switch (noContentType) {
			case NoContentType.LoggedInNotExist:
				message = <div>We are sorry. The content you have requested does not exist. You will be returned to the dashboard.</div>;
				break;
			case NoContentType.LoggedInNotEnrolled:
				message = <div>We are sorry. This content has not been assigned to you. Please contact your ucSKILLS Administrator.<br />You will be returned to the dashboard.</div>;
				break;
			default:
				break;
		}
		const onClick = (noContentType !== NoContentType.NotLoggedIn)
			? () => navigate("/dashboard", { replace: true })
			: () => {
				localStorage.setItem("ucs-lau-anon", location.pathname + location.search + location.hash);
				logout();
			};
		openModal("", message, null, onClick);
	}, [navigate, location, logout, openModal]);

	// Effect that processes the URL hash.
	useEffect(() => {
		if (location.hash) {
			resetPlayerDimensions();
			idList.current = location.hash.slice(1).split('-'); // Format is #courseId-*-moduleId-*-submoduleId-*
			setFetchRequestData(`/api/v3/PlayerData/${idList.current[3]}/${idList.current[5]}`);
		}
		else {
			handleOpenNoContentModal(sessionStorage.getItem("userData") ? NoContentType.LoggedInNotExist : NoContentType.NotLoggedIn);
		}
	}, [location, handleOpenNoContentModal, setFetchRequestData]);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/PlayerData/{moduleId}/{submoduleId} - PackageLinkDto || AssessmentLinkDto
	 *   2) PUT /api/v3/Assessment/Attempt - TRUE
	 *   2) PUT /api/v3/Assessment/Complete - TRUE
	 */
	const handleData = useCallback((fetchedData) => {
		if (!fetchedData) {
			handleOpenNoContentModal(NoContentType.LoggedInNotExist);
			return;
		}
		if (fetchedData.Path) {
			expectedMessageOrigin.current = new URL(fetchedData.Path).origin;
		}

		if (fetchedData.Assessment) {
			const options = getFetchOptions({
				method: "PUT",
				body: {
					name: fetchedData.Assessment.Name,
					registrationId: fetchedData.RegistrationId,
					module: fetchedData.Module,
					submodule: fetchedData.Submodule,
					contentId: fetchedData.Id
				}
			});
			setFetchRequestData("/api/v3/Assessment/Attempt", options);
			setAssessment(true);
			setResetAssessment(true);
		}
		else if (fetchedData.TableOfContents) {
			let all = JSON.parse(fetchedData.TableOfContents);
			fullToC.current = all.map((curr, index) => {
				curr.no = index;
				return curr;
			});
			const filteredSections = fullToC.current.filter((curr) => {
				return (
					(curr.title.toLocaleLowerCase() !== "completed") &&
					(!curr.title.toLocaleLowerCase().startsWith("slide")) &&
					(isNaN(curr.title))
				);
			});
			setSidebarSections(filteredSections);
			setDetailNavigationSections({ next: filteredSections[0] });

			// Set sidebarPresent based on whether there are sections.
			setSidebarPresent(filteredSections.length > 0);
		}
		if (typeof fetchedData !== "boolean") {
			setData(fetchedData);
		}
	}, [handleOpenNoContentModal]);

	const handleError = useCallback((error) => {
		switch (error.Status) {
			case 400:
				if (error.Message === "NOT_ENROLLED") {
					handleOpenNoContentModal(NoContentType.LoggedInNotEnrolled);
				}
				break;
			case 404:
				handleOpenNoContentModal(NoContentType.LoggedInNotExist);
				break;
			default:
				break;
		}
	}, [handleOpenNoContentModal]);

	const handleCompleteAssessment = (assessment) => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				name: assessment.Name,
				test: assessment.Test,
				registrationId: data.RegistrationId,
				correctAnswers: assessment.correctAnswers,
				success: assessment.passed,
				module: data.Module,
				submodule: data.Submodule,
				contentId: data.Id,
				questions: assessment.pages.map(x => {
					const question = x.elements[0];
					const description = (question.type !== "boolean") ? question.title.substring(question.title.indexOf(". ") + 2) : question.description;
					return {
						name: question.name,
						description: description,
						type: question.type,
						possibleAnswers: question.choices,
						selectedAnswer: x.answer,
						correctAnswer: question.correctAnswer,
						feedback: x?.feedback ?? ""
					}
				})
			}
		});
		setFetchRequestData("/api/v3/Assessment/Complete", options);
	};

	const handleRestartAssessment = () => {
		if (idList.current) {
			setFetchRequestData(`/api/v3/PlayerData/${idList.current[3]}/${idList.current[5]}`);
		}
		else {
			handleOpenNoContentModal(sessionStorage.getItem("userData") ? NoContentType.LoggedInNotExist : NoContentType.NotLoggedIn);
		}
	};

	const handlePostMessage = useCallback((messageData) => {
		const path = (packageIsScorm(data.Type)) ? data.ScormData.ProxyUri : data.Path;
		const destination = mediaRef && mediaRef.current;
		if (destination && destination.contentWindow) {
			console.debug("Posting message:", messageData.action, messageData, path);
			destination.contentWindow.postMessage(JSON.stringify(messageData), path);
		}
		else {
			console.warn("MediaRef or destination contentWindow is null");
		}
	}, [mediaRef, data]);

	const handleSelectSection = (index) => {
		const selectedSectionData = sidebarSections[index];
		setSelectedSection(selectedSectionData.id);
		setDetailNavigationSections({
			prev: index > 0 ? sidebarSections[index - 1] : null,
			next: index < sidebarSections.length - 1 ? sidebarSections[index + 1] : null
		});
		const feedbackInformation = {
			courseId: data.Course.Id,
			moduleId: data.Module.Id,
			submoduleId: data.Submodule.Id,
			sectionId: parseInt(selectedSectionData.id, 10)
		};
		sessionStorage.setItem('feedbackInformation', JSON.stringify(feedbackInformation));
	};

	const getSectionById = (id) => {
		return sidebarSections.findIndex((curr) => {
			return String(curr.id) === String(id);
		});
	};

	const handleMediaLoaded = () => {
		if (packageIsScorm(data.Type)) {
			return;
		}

		// Hide the TOC.
		handlePostMessage({ action: "hideToc" });

		// Request container dimensions.
		handlePostMessage({ action: "getDimensions" });

		// Highlight last accessed slide number.
		if (sidebarSections) {
			const index = sidebarSections.findIndex((curr) => {
				return curr.title === data.Current;
			});
			if (index !== -1) {
				handleSelectSection(index);
			}
		}
	};

	const handleClickSectionItem = (id) => {
		if (sidebarSections) {
			const index = getSectionById(id);
			if (index !== -1) {
				handleSelectSection(index);
				handlePostMessage({ action: data.Type === PackageType.xAPI_CAPTIVATE ? "setSlide" : "setSection", no: sidebarSections[index].no });
			}
		}
	};

	const markCurrentSectionAsWatched = (index) => {
		let shallowSectionsCopy = [...sidebarSections];
		shallowSectionsCopy[index].visited = 100;
		setSidebarSections(shallowSectionsCopy);
	};

	const handleSlideEnter = (messageData) => {
		if (sidebarSections) {
			console.debug("ucSKILLS::Recieved", messageData.eventName);
			let index = -1;
			if (data.Type === PackageType.xAPI_CAPTIVATE) {
				index = sidebarSections.findIndex((curr) => {
					return curr.no === messageData.slideNumber - 1;
				});
				if (index === -1) {
					// Go back to nearest sidebar section.
					const previousNamedSections = sidebarSections.filter(x => x.no <= messageData.slideNumber - 1);
					if (previousNamedSections.length) {
						index = previousNamedSections.length - 1;
					}
				}
			}
			else if (messageData.sectionData) {
				index = getSectionById(messageData.sectionData.Id);
			}
			if (index !== -1) {
				handleSelectSection(index);
				if (data.Type === PackageType.xAPI_CAPTIVATE) {
					markCurrentSectionAsWatched(index);
				}
			}
			else {
				console.warn("ucSKILLS: Data sent by Captivate content cannot map to the Sidebar content.", messageData);
			}
		}
	};

	/** @note: Dev wrapped content only */
	const handlePlaySection = (messageData) => {
		if (messageData.sectionData) {
			const index = getSectionById(messageData.sectionData.Id);
			if (index !== -1) {
				setTimeout(() => markCurrentSectionAsWatched(index), 2000); // Give it 2 seconds before updating
			}
		}
	};

	const handleReceiveDimensions = (messageData) => {
		console.debug("PlayerPage: W = " + messageData.boundingRect.width);
		console.debug("PlayerPage: H = " + messageData.boundingRect.height);

		setBreadcrumbHeight(breadcrumbHeight.current);
		setReceivedPlayerWidth(messageData.boundingRect.width);
		setReceivedPlayerHeight(messageData.boundingRect.height);
	};

	const messageMap = {
		"CPAPI_SLIDEENTER": handleSlideEnter,
		"SECTIONSTART": handleSlideEnter,
		"PLAYSECTION": handlePlaySection,
		"DIMENSIONS": (messageData) => {
			handleReceiveDimensions(messageData);
		},
		"UNLOAD": postTerminate
	};

	usePlayerMessageReceiver(expectedMessageOrigin.current, messageMap, unloading);

	// Scrolling
	useEffect(() => {
		if (sidebarSections) {
			// Scroll to the top of the player-container on load
			const playerContainer = document.getElementById('player-container');
			if (playerContainer) {
				playerContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}, [sidebarSections]);

	// On exiting the page...
	useEffect(() => {
		if (blocker.state === "blocked") {
			if (!assessment && data.Type !== PackageType.xAPI_CAPTIVATE) {
				// Xapi and SCORM send unload messages; the response data is used to write LRS termination events.
				handlePostMessage({ action: "unload", v3: true });
			}
			else {
				// Captivate has no unload communication.
				postTerminate({});
			}
		}
	}, [blocker.state, assessment, handlePostMessage, postTerminate, data]);

	return (
		<>
			<DataFetcher fetchData={fetchData} setData={handleData} setError={handleError} />
			{data !== null && (
				<div className={`player ${sidebarPresent ? 'withSidebar' : 'withoutSidebar'}`}>
					<div id="player-container" className="player-container" style={{ width: calculatedPlayerWidth }}>
						<PlayerBreadcrumb
							course={data.Course}
							module={data.Module}
							submodule={data.Submodule.Name}
							setBreadcrumbHeight={(value) => { breadcrumbHeight.current = value; }}
						/>
						{assessment ? (
							<SurveyContainer assessment={data.Assessment} onComplete={handleCompleteAssessment} onRestart={handleRestartAssessment} resetAssessment={resetAssessment} setResetAssessment={setResetAssessment} isSurveyComplete={isSurveyComplete} setIsSurveyComplete={setIsSurveyComplete} />
						) : (
							<PlayerMedia
								data={data}
								mediaRef={mediaRef}
								handleMediaLoaded={handleMediaLoaded}
								calculatedPlayerHeight={calculatedPlayerHeight}
							/>
						)}
						<PlayerDetails
							previousSection={detailNavigationSections.prev}
							nextSection={detailNavigationSections.next}
							submodule={data.Submodule}
							courseLink={data.Course}
							moduleLink={data.Module}
							handleClickSectionItem={handleClickSectionItem}
							isSurveyComplete={isSurveyComplete}
							setIsSurveyComplete={setIsSurveyComplete}
						/>
					</div>
					{!assessment && sidebarPresent &&
						<PlayerSidebar
							sectionsArray={sidebarSections}
							selectedSection={selectedSection}
							handleClickSectionItem={handleClickSectionItem}
							module={data.Module}
							submodule={data.Submodule}
						/>
					}
				</div>
			)}
		</>
	);
};

export default Player;

import { useCallback, useEffect, useRef, useState } from "react";
import { SEPARATOR } from "utils/constants";

const useUserData = () => {
	const cache = useRef([]);
	const [data, setData] = useState(null);
	const [username, setUsername] = useState("");
	const [userData, setUserData] = useState({});

	useEffect(() => {
		if (cache.current.length) {
			setData(cache.current);
		}
		else {
			let storageData = sessionStorage.getItem("userData");
			if (storageData) {
				storageData = window.atob(storageData);
				cache.current = storageData.split(SEPARATOR);
				setData(cache.current);
			}
		}
		if (data) {
			setUsername(data[1]);
			setUserData({
				Name: data[0],
				Email: data[1],
				UserLevel: data[3],  
				Company: data[4],
			});
		}
	}, [data]);

	const updateSessionStorage = useCallback((newName, newUsername) => {
		cache.current[0] = newName;
		cache.current[1] = newUsername;
		setData(cache.current);
		const storageData = window.btoa(cache.current.join(SEPARATOR));
		sessionStorage.setItem("userData", storageData);
	}, []);

	return {
		username,
		userData,
		updateSessionStorage
	};
};

export default useUserData;

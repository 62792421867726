import "./Feedback.scss";
import { useState, useEffect, useRef, useCallback } from "react";
import { faFaceSmile, faFaceFrown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../components/Button/Button";
import { getFetchOptions } from "../../utils/fetch";
import { DataFetcher } from "../../components/DataFetcher";
import { useLocation } from "react-router-dom";
import AppRoutes from "../../setup/AppRoutes";

const Feedback = () => {
	const mobileWidth = 768;
	const countdownTimerLength = 5;
	const feedbackRef = useRef(null);
	const countdownRef = useRef(countdownTimerLength);
	const timerRef = useRef(null);
	const location = useLocation();

	const [feedbackState, setFeedbackState] = useState(
		sessionStorage.getItem("feedbackMinimized") === "true" ? "minimised" : "rest"
	);
	const [happy, setHappyFace] = useState(true);
	const [comments, setComments] = useState("");
	const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
	const [displayCountdown, setDisplayCountdown] = useState(countdownRef.current);
	const [fetchData, setFetchData] = useState({});

	const toggleFeedbackState = (newState) => {
		setFeedbackState(newState);
		switch (newState) {
			case "rest":
				countdownRef.current = countdownTimerLength;
				setDisplayCountdown(countdownRef.current);
				sessionStorage.removeItem("feedbackMinimized");
				break;
			case "minimised":
				sessionStorage.setItem("feedbackMinimized", "true");
				break;
			default:
				break;
		}
	};

	const handleSubmit = () => {
		const currentRoute = AppRoutes.find(x => x.path === location.pathname);
		const feedbackContextType = currentRoute ? currentRoute.feedbackContext : null;
		const context = currentRoute && (currentRoute.path !== "/player" && currentRoute.path !== "/course-view")
			? currentRoute.pageName
			: sessionStorage.getItem('feedbackInformation');
		const options = getFetchOptions({
			method: "POST",
			body: {
				Type: feedbackContextType,
				Context: context,
				Happy: happy,
				Reason: comments
			}
		});
		setFetchData({ url: `api/v2/Feedback/`, options: options });
	};

	const handleData = useCallback((data) => {
		if (data) {
			setFeedbackState("submitted");
			countdownRef.current = countdownTimerLength;
			setDisplayCountdown(countdownRef.current);
			startCountdown();
		}
	}, []);

	const handleClose = () => {
		setComments("");
		toggleFeedbackState("minimised");
		clearInterval(timerRef.current);
	};

	const startCountdown = () => {
		clearInterval(timerRef.current);
		timerRef.current = setInterval(() => {
			countdownRef.current -= 1;
			setDisplayCountdown(countdownRef.current);
			if (countdownRef.current <= 0) {
				handleClose();
			}
		}, 1000);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < mobileWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (feedbackRef.current && !feedbackRef.current.contains(event.target) && feedbackState !== "minimised") {
				toggleFeedbackState("rest");
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [feedbackState]);

	const feedbackClass = (
		feedbackState === "rest" ? "small" : (feedbackState === "input" || feedbackState === "submitted" ? "wide" : "minimised")
	);

	return (
		<div className={`feedback ${feedbackClass}`} ref={feedbackRef}>
			<DataFetcher fetchData={fetchData} setData={handleData} />
			{feedbackState !== "minimised" && (
				<div
					className={`feedback-close ${feedbackState !== "rest" || isMobile ? "active" : ""} ${isMobile && feedbackState === "rest" ? "close-mobile" : ""}`}
					onClick={handleClose}
				>
					<FontAwesomeIcon icon={faXmark} />
				</div>
			)}
			<div className={`feedback-rest ${feedbackState === "rest" ? "active" : ""}`}>
				<div className="feedback-rest-text">
					<p>Rate this page</p>
				</div>
				<div className="feedback-rest-faces">
					<div
						className="feedback-rest-faces-happy"
						onClick={() => {
							setHappyFace(true);
							toggleFeedbackState("input");
						}}
					>
						<FontAwesomeIcon icon={faFaceSmile} />
					</div>
					<div
						className="feedback-rest-faces-sad"
						onClick={() => {
							setHappyFace(false);
							toggleFeedbackState("input");
						}}
					>
						<FontAwesomeIcon icon={faFaceFrown} />
					</div>
				</div>
			</div>

			<div className={`feedback-input ${feedbackState === "input" ? "active" : ""}`}>
				<div className="feedback-input-faces">
					<div
						className={`feedback-input-faces-happy ${happy === true ? "active" : ""}`}
						onClick={() => setHappyFace(true)}
					>
						<FontAwesomeIcon icon={faFaceSmile} />
					</div>
					<div
						className={`feedback-input-faces-sad ${happy === false ? "active" : ""}`}
						onClick={() => setHappyFace(false)}
					>
						<FontAwesomeIcon icon={faFaceFrown} />
					</div>
				</div>
				<textarea
					placeholder="Additional comments..."
					value={comments}
					onChange={(e) => setComments(e.target.value)}
				/>
				<div className="feedback-input-button">
					<Button text="Submit" onClick={handleSubmit} />
				</div>
			</div>

			<div className={`feedback-submitted ${feedbackState === "submitted" ? "active" : ""}`}>
				<h4>Thank you for your feedback</h4>
				<p>Your comments help us provide you with the best experience possible.</p>
				<Button text={`Close (${displayCountdown})`} onClick={handleClose} />
			</div>

			{feedbackState === "minimised" && (
				<div className="feedback-minimised" onClick={() => toggleFeedbackState("rest")}>
					<p>Feedback</p>
				</div>
			)}
		</div>
	);
};

export default Feedback;
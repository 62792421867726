import { DataFetcher } from "components/DataFetcher";
import ListNavigation from "components/ListNavigation/ListNavigation";
import Pod from "components/Pod/Pod";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./CourseView.scss";
import CourseItem from "./components/CourseItem";
import useScrollToPageArea from "hooks/useScrollToPageArea";

const MODULE_ID_TEXT = "moduleId";
const COURSE_ID_TEXT = "courseId";

const CourseView = () => {
	const { hash } = useLocation();
	const fetchData = { url: "/api/v3/CoursesAndModules/ByCurrentUser" };
	const [data, setData] = useState(null);
	const [expandedModuleId, setExpandedModuleId] = useState(null);
	const [courseAndModuleData, setCourseAndModuleData] = useState(null);
	const { scroll } = useScrollToPageArea();
	const [scrollTarget, setScrollTarget] = useState(null); // Track scroll target

	/*
	 * Effect for processing the returned data from the DataFetcher.
	 * This needs to be retained as a useEffect (instead of using a useCallback) - otherwise the scrolling doesn't work.
	 */
	useEffect(() => {
		if (data && !courseAndModuleData) {
			data.sort((a, b) => { return a.Position - b.Position });
			for (let item of data) {
				item.SubContent.sort((a, b) => {
					return a.Position - b.Position;
				});
			}
			setCourseAndModuleData(data);
		}
	}, [data, courseAndModuleData]);
	
	const parseIdFromHash = (hash, idText) => {
		const idIndex = hash.indexOf(idText);
		if (idIndex !== -1) {
			return Number(hash.substring(idIndex + idText.length + 1));
		}
		return null;
	};
		
	useEffect(() => {
		if (hash) {
			const element = hash.slice(1);
			setScrollTarget(element);

			const courseId = parseIdFromHash(element, COURSE_ID_TEXT);
			const moduleId = parseIdFromHash(element, MODULE_ID_TEXT);

			const feedbackInformation = {
				courseId,
				moduleId
			};
			sessionStorage.setItem('feedbackInformation', JSON.stringify(feedbackInformation));

			if (moduleId !== null) {
				setExpandedModuleId(moduleId);
			}
		}
	}, [hash]);
	
	// Effect for Scrolling.
	useEffect(() => {
		if (scrollTarget && courseAndModuleData) {
			scroll(scrollTarget); 
			setScrollTarget(null); 
		}
	}, [scrollTarget, courseAndModuleData, scroll]);

	const handleExpandModule = (moduleId) => {
		setExpandedModuleId(prev => (prev === moduleId ? null : moduleId)); 
	};

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div className="grid-two-col gutters">
				<Pod title="Courses">
					{courseAndModuleData && (
						<div className="list-view">
							{courseAndModuleData.map((course) => (
								<CourseItem
									key={course.Id}
									course={course}
									expandedModuleId={expandedModuleId}
									onExpand={handleExpandModule}
								/>
							))}
						</div>
					)}
				</Pod>
				<Pod title="Course Navigation" mobileOrder={1} sticky>
					{courseAndModuleData && <ListNavigation items={courseAndModuleData} />}
				</Pod>
			</div>
		</DataFetcher>
	);
};

export default CourseView;

import CourseView from "../features/CourseView/CourseView";
import Dashboard from "../features/Dashboard/Dashboard";
import Player from "../features/Player/Player";
import SearchResults from "../features/Search/SearchResults/SearchResults";
import Help from "../features/Help/Help";
import Profile from "../features/Profile/Profile";
import UserManagement from "../features/UserManagement/UserManagement";
import Timeline from "../features/Reports/Timeline/Timeline";
import ContentUsage from "../features/Reports/ContentUsage/ContentUsage";
import DeviceSelection from "../features/UserManagement/DeviceSelection/DeviceSelection";
import { FeedbackStateType } from "../features/Feedback/FeedbackStateType";

const AppRoutes = [
	{
		path: "/dashboard",
		element: <Dashboard />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "Dashboard"
	},
	{
		path: "/course-view",
		element: <CourseView />,
		feedbackContext: FeedbackStateType.Course,
		pageName: "Course View"
	},
	{
		path: "/player",
		element: <Player />,
		feedbackContext: FeedbackStateType.Module,
		pageName: "Player"
	},
	{
		path: "/searchresults",
		element: <SearchResults />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "Search Results"
	},
	{
		path: "/help",
		element: <Help />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "Help"
	},
	{
		path: "/profile",
		element: <Profile />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "Profile"
	},
	{
		path: "/timeline",
		element: <Timeline />,
		feedbackContext: FeedbackStateType.Report,
		pageName: "Timeline"
	},
	{
		path: "/contentusage",
		element: <ContentUsage />,
		feedbackContext: FeedbackStateType.Report,
		pageName: "Content Usage"
	},
	{
		path: "/usermanagement",
		element: <UserManagement />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "User Management"
	},
	{
		path: "/deviceselection",
		element: <DeviceSelection />,
		feedbackContext: FeedbackStateType.Page,
		pageName: "Device Selection"
	}
];

export default AppRoutes;

/**
	* @readonly
	* @enum {number}
	*/
export const FeedbackStateType = Object.freeze({
	Page: 1,
	Report: 2,
	Course: 3,
	Module: 4,
	Submodule: 5,
	Section: 6
});